@font-face {
	font-family: "Telegraphem";
	src: url("./fonts/Telegraphem.otf");
}

body {
	margin: 0;
	font-family: '"Telegraphem", "Helvetica Neue", Helvetica, Arial, sans-serif';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
